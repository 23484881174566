body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #292929;
}

html {
	-webkit-tap-highlight-color: transparent;
	--color-accent: #00FFFF;
	--color-secondary: #DD055E;
}

.margin-privacy {
	margin-top: 1rem;
}

.footer-flex {
	display: flex;
	flex-direction: column;
}

.outlinedz {
	background: transparent !important;
	color: #DD055E !important;
	font-weight: bold !important;
	margin-left: 2rem !important;
	border: 1px solid #DD055E !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

.fit-margin {
	margin-top: -1.1rem;
	text-align: center;
}

.landingbutton {
	margin-bottom: 4rem;
}

.chat-div {
	text-align: center;
	z-index: 500000;
}

.chat {
	position: absolute;
	left: 100px;
	right: 10px;
	bottom: 5px;
	top: 5px;
	max-width: 120px;
}

.menulink {
	margin: 0 2rem;
}

a {
	color: white !important;
	text-decoration: none !important;
}

/* MenuOverlay */

.MuiBox-root:focus-visible {
	border: none !important;
	border-radius: 0 !important;
	outline: 0 !important;
}

.MuiBox-root:focus {
	border: none !important;
	border-radius: 0 !important;
	outline: 0 !important;
}

.MuiBackdrop-root {
	background-color: rgba(0, 0, 0, 0.82) !important;
}

.overlay {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 4000;
	top: 0;
	left: 0;
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0, 0.9);
	overflow-x: hidden;
	transition: 0.5s;
}

.overlay-content {
	position: relative;
	top: 10%;
	width: 100%;
	text-align: center;
	margin-top: 30px;
}

.overlay a {
	text-decoration: none;
	font-size: 16px;
	color: #818181;
	display: block;
	transition: 0.3s;
	font-family: Righteous, serif;
}

.overlay-content a {
	padding: 28px;
}

.overlay a:hover, .overlay a:focus {
	color: #f1f1f1;
}

.overlay .closebtn {
	position: absolute;
	min-height: 40px;
	z-index: 30000;
	top: 32px;
	right: 20px;
	text-align: center;
	display: block;
}

#myVideo {
	position: fixed;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
}

.logo-mobile {
	top: 100px;
	position: relative;
	z-index: 1000;
	max-width: 400px;
}

.logo-mobile-non-video {
	top:0;
	left:40px;
	position: relative;
	z-index: 1000;
	max-width: 400px;
}

.logo-footer {
	max-width: 200px;
	margin-top: 1rem;
	display: none;
}

.containment {
	margin: 0 auto;
	max-width: 1800px;
}

.footer {
	display: flex;
	position: static;
	justify-content: space-between;
	align-items: center;
	height: 180px;
	background-color: black !important;
	color: white;
	bottom: 0;
	width: 100%;
}

.footer-left {
	flex: 1;
	margin-left: 2rem;
}

.footer-center {
	flex: 1;
	text-align: center;
}

.footer-center > img {
	margin: 0 2rem;
	width: auto;
}

.footer-right {
	flex: 1;
	text-align: right;
	margin-top: 1rem;
	margin-right: 2rem;
}

.MuiAppBar-root {
	background: rgba(0, 0, 0, 0.3) !important
}

.MuiToolbar-root {
	justify-content: space-between;
}

.page-title {
	width: 100%;
	position: absolute;
	background: transparent;
	color: white;
	top: 23%;
	margin-left: 5rem;
}

/* container */
.columns-3 {
	width: 100%;
	display:flex;
	flex-wrap: wrap;
}
/* columns */
.columns-3 > * {
	width: calc(100% / 3);
	color: white;
}
@media (max-width: 992px) {
	.columns-3 > * {
		width: 100%;
 }
}
/* Optional */

.landing-location {
	position: absolute;
	color: var(--color-accent);
	font-family: 'Rock Salt', cursive;
	font-size: calc(14px + .5vw);
	bottom: calc(2.2rem + 1.2vw);
}

.landing-title {
	text-decoration: underline;
	font-family: 'Righteous', serif;
	font-size: calc(24px + 1vw);
}

.activeblue {
	color: #DD055E !important;
}

.activepink {
	color: #DD055E !important;
}

.hero-flex {
	z-index: 1000 !important;
	display: flex;
	flex-direction: row;
	position: relative;
	width: 100vw;
	height: fit-content;
}

.hero-flex:before {
	content: ' ';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0.4;
	background-image: url("./img/punti.png");
	background-repeat: repeat;
	background-size: 40%;
}

.insieme {
	width: 303px;
	position: absolute;
	bottom: -140px;
	right: -21px;
}

.hero-flexbox-item {
	display: flex;
	flex-direction: column;
	flex-basis: 50%;
}

.hero-flexbox-item-2 {
	display: flex;
	flex-direction: column;
	flex-basis: 50%;
	max-height: 1200px;
}

.hero-flexbox-item > .hero-card {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 250px;
}

.hero-card-modal > img {
	max-width: 150px;
}

.hero-card > img {
	max-width: 130px;
	opacity: .7;
	/*transition: 0.1s;*/
}

.hero-card:hover > img {
	opacity: 1;
	cursor: pointer;
}

.hero-flexbox-item > .hero-card > a {
	position: absolute;
	margin-top: 6px;
	font-size: 1.5rem;
	font-family: Righteous, serif;
}

.bg-cover {
	background-size: cover;
	border-radius: 2rem;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url('img/auto-herolist.png');
	margin: 4rem 4rem;
	width: 60%;
	flex-basis: 100%;
	max-height: 800px;
}

.bg-cover-2 {
	background-size: cover;
	border-radius: 2rem;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url('img/sasha.jpeg');
	margin: 4rem 4rem;
	width: 60%;
	flex-basis: 100%;
	max-height: 800px;
}

.bg-cover-3 {
	background-size: cover;
	border-radius: 2rem;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url('img/luga.jpeg');
	margin: 4rem 4rem;
	width: 60%;
	flex-basis: 100%;
	max-height: 800px;
}

.heroList::after {
	position: absolute;
	content: ' ';
	z-index: -1000;
	left: 0;
	top: 0;
	border-top: 100vh solid rgba(3, 218, 197, 0.1);
	border-right: 99vw solid transparent;
	width: 0;

}

header {
	max-width: 1800px;
	margin: 0 auto;
}

.navbar {
	background: #ffffff0a;
	height: 100px;
	width: 100vw;
	display: table-cell;
	position: relative;
	vertical-align: middle;
}

.navbar > div {
	font-family: Righteous, serif;
	align-items: center;
	display: flex;
	height: 100px;
	margin-left: 2rem;
	justify-content: flex-start;
}

.navbar * a {
	text-decoration: none;
	font-size: 1rem;
	margin: 0 2rem;
}

.navbar > a > img {
	max-width: 250px;
	/*margin-left: 4rem;*/
}

.header-img {
	width: 100%;
	height: 40vh;
	background-position-y: top;
	object-fit: cover;
	filter: brightness(.5);
}

.absolute {
	position: absolute;
}

video {
	/* override other styles to make responsive */
	width: 100%;
	height: 20vh;
	background-position-y: top;
	filter: brightness(.5);
	margin-top: -27px;
	object-fit: cover;
	object-position: 50% 90%;
}

/* Footer CSS */

.container-footer {
	text-align: center;
}

.container-footer > img {
	max-width: 800px;
	position: relative;
	height: 100%;
	margin-top: 8rem;
	margin-bottom: 4rem;
	padding: 4rem;
}


.ctaButton {
	position: relative;
	color: var(--color-accent);
	border: 2.81301px solid var(--color-accent);
	width: max-content;
	box-sizing: border-box;
	background: transparent;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: calc(12px + .5vw) calc(22px + .5vw);
	font-family: 'Righteous', serif;
	font-size: calc(12px + .5vw);
}

section {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
	font-family: 'Roboto', sans-serif;
}

.container-title {
	padding: 4rem;
	width: 100%;
}

.cappello-container {
	width: 100%;
	margin: 4rem 4rem;
	text-align: center !important;
}

.cappello-container {
	margin: 8rem auto 3rem auto !important;
	position: relative;
}

.cappello {
	position: relative;
	font-family: 'Righteous', serif;
	font-style: normal;
	font-weight: 400;
	font-size: 25px;
	line-height: 30px;
	letter-spacing: -.02rem;
	text-transform: uppercase;
	color: #00FFFF;
}

.landing-button {
	margin: 4rem 0rem;
}

.ochre {
	background-color: #DD055E !important;
	color: white !important;
	font-weight: bolder !important;
}

.white {
	background-color: #ffffff !important;
	color: black !important;
	font-weight: bolder !important;
}

.whatsapp {
	background-color: #24d366 !important;
	margin-left: auto !important;
	margin-right: 4rem !important;
	color: black !important;
	font-weight: bolder !important;
}

.whatsrapp {
	background-color: #24d366 !important;
	margin-left: auto !important;
	margin-right: 0 !important;
	margin-top: 2rem !important;
	color: black !important;
	font-weight: bolder !important;
}

.paragraph {
	color: #fff;
	font-weight: 200;
	font-size: 20px;
	font-family: 'Open Sans', sans-serif;
}

.sub-cappello {
	font-weight: 400;
	font-size: 50px;
	line-height: 42px;
	letter-spacing: .7px;
	color: rgba(255, 255, 255, 0.87);
	margin-top: 2rem;
	font-family: 'Racing Sans One', sans-serif;
}

.cardone {
	background-color: #212121;
	margin: 2rem;
	min-height: 480px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.bg-pink hr {
	background-color: #fff;
}

.bg-pink > .cardone {
	background-color: #DD055E !important;
}

.sub-titolone {
	font-weight: 400;
	font-size: 32px;
	line-height: 42px;
	letter-spacing: .7px;
	color: rgba(255, 255, 255, 0.87);
	padding-left: 1.5rem;
	padding-top: .5rem;
	font-family: 'Racing Sans One', sans-serif;
}

.marginz {
	margin-left: 3.5rem;
}

hr {
	height: 2px;
	background-color: #dd275e85;
	border: none;
	margin: 2rem 1.5rem;
}

.subtitolone {
	color: #fff;
	margin-top: 2rem;
	font-weight: 200;
	font-size: 25px;
	font-family: 'Open Sans', sans-serif;
}

.para {
	color: #fff;
	font-weight: 200;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-top: 1.5rem;
	font-size: 20px;
	font-family: 'Open Sans', sans-serif;
}

h1 {
	font-weight: 400;
	font-size: 32px;
	line-height: 42px;
	letter-spacing: .7px;
	color: rgba(255, 255, 255, 0.87);
	/*padding-left: 1.5rem;*/
	padding-top: 0.5rem;
	font-family: 'Racing Sans One', sans-serif;
}

h3 {
	font-family: 'Righteous', serif;
	font-style: normal;
	font-weight: 400;
	font-size: 36.3364px;
	line-height: 31px;
	letter-spacing: -0.757009px;
	font-variant: all-small-caps;
	color: #dd275e !important;
}

p {
	font-family: 'Roboto', serif;
	font-style: normal;
	font-weight: 400;
	font-size: 18.1682px;
	line-height: 23px;
	/*text-align: justify;*/
	color: rgba(255, 255, 255, 0.63);
}


/* Hero List First Flex*/

.flex-container-list {
	display: flex;
	flex-wrap: wrap;
	z-index: 1;
	justify-content: space-evenly;
	align-items: flex-start;
}

.flex-container-list > .flex-item-card {
	flex-basis: 35%;
}

.flex-container-list > .flex-item-description {
	margin-top: 100px;
	flex-basis: 35%;
}

.flex-container-list > .flex-item-card-1 {
	padding: 4rem;
	flex-basis: 100%;
}

.flex-container-list > .flex-item-description-1 {
	margin-top: 100px;
	padding-bottom: 100px;
	flex-basis: 30%;
}

.container-description {
	margin-top: 4rem;
	margin-left: 2rem;
}

.triangle-text {
	/*padding-right: 2rem;*/
}

/*.triangle-container {*/
/*	position: relative;*/
/*	width: 60%; !* Adjust as needed *!*/
/*	height: 600px; !* Adjust as needed *!*/
/*	text-align: justify;*/
/*}*/


body {
	animation: fadeInAnimation ease 3s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


.container-cappello {
	margin-left: 5rem;
}

/* Hero List Card List Flex*/

.flex-container-cards {
	/*margin-top: 8rem;*/
	display: flex;
	flex-wrap: wrap;
	z-index: 1;
	position: relative;
	justify-content: space-evenly;
	align-items: flex-start;
	gap: 8rem 0;
}


.flex-container-cards > .flex-item-card {
	flex-basis: 35%;
}

.flex-container-cards > .flex-item-description {
	flex-basis: 35%;
}

.flex-container-cards > * {
	margin-bottom: 0px;
	margin-right: 10px;
	margin-top: 4rem;
}

.flex-item-card {
	display: flex;
	flex-direction: column;
	justify-content: normal;
	align-items: center;
	flex-wrap: wrap;
}

.card-text-description {
	align-items: flex-start;
}

.corsi_list {
	display: flex;
	flex-direction: column;
	flex-basis: 30%;
	padding-left: 2rem;
	list-style-type: none;
}

.flex-item-description {
	flex-basis: 50%;
	margin-top: 8rem;
}

.flex-item-description > div > img {
	width: 90%;
}

.corsi_list > li {
	display: flex;
	padding-left: 4rem;
	align-items: center;
	font-family: 'Righteous', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 80px;
	letter-spacing: -1px;
	font-variant: all-small-caps;
	color: rgba(255, 255, 255, 0.87);
}

.corsi_list > li > span {
	font-size: 26px;
	margin-left: 2rem;
	letter-spacing: 1px;
}

.corsi_list > li > img {
	width: 200px;
}

.custom-button {
	background-color: transparent;
	border: transparent;
	position: relative;
	display: inline-block;
	width: 167px;
	height: 120px;
	right: 20px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	background-image: url('img/custom_button.png');
}

.button-top {
	margin-left: 50px;
	margin-top: -20px;
}

.custom-button-moto {
	background-color: transparent;
	border: transparent;
	position: relative;
	display: inline-block;
	width: 167px;
	height: 120px;
	right: 20px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	background-image: url('img/custom_button_moto.png');
}

.button-moto {
	height: 120px;
	top: -50px;
	right: 6px;
}

footer {
	height: 100px;
}

@media (max-width: 1400px) {
	.navbar {
		display: none;
	}
}

@media (min-width: 1400px) {
	.menu-icon-mobile {
		display: none !important;
	}
}

@media (max-width: 1400px) {
	.menu-icon-mobile {
		position: absolute;
		color: #fff;
		width: 50px;
		min-height: 40px;
		z-index: 30000;
		top: 30px;
		right: 20px;
		text-align: center;
		display: block;
	}
}

@media (max-width: 900px) {

	video {
		height: 20vh;
	}

	.alignMobile {
		text-align: left;
	}

	.ochre, .landing-button {
		width: 86%
	}

	.sub-cappello {
		font-size: 18px;
		line-height: 28px;
		/*padding-left: 2rem;*/
		/*padding-right: 2rem;*/
	}

	.sub-titolone {
		font-size: 28px;
	}

	.cardone {
		margin: 0rem !important;
		margin-bottom: 2rem !important;
	}

	.marginz {
		margin-left: 1rem;
	}

	.subtitolone {
		font-size: 14px !important;
		padding-left: 1rem;
		padding-right: 1rem;
		margin-top: 1rem !important;
	}

	.footer-left {
		margin-left: 0 !important;
	}

	.footer-right {
		margin-right: 0 !important;
	}

	h3 {
		font-size: 28px;
	}

	.insieme-mobile {
		max-width: 400px;
		position: absolute;
		bottom: -141px;
		right: -297px;
	}

	.header-img {
		height: 40vh;
		margin-top: 0;
	}

	.logo-mobile {
		position:absolute;
		width:200px;
		min-height:40px;
		z-index:30000;
		top:20px;
		left:20px;
		text-align:center;
		display: block;
	}

	.logo-footer {
		max-width: 150px;
		margin-top: 1rem;
		display: block;
	}

	.MuiModal-root {
		margin-top: 62px;
	}

	.item-text {
		margin: -2rem 4rem 8rem 4rem;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		flex-basis: 100% !important; /* One item per row */
	}

	.container-cappello {
		margin-left: 0 !important;
	}

	.footer {
		display: flex;
		font-size: 12px;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 180px;
		background-color: rgba(0, 0, 0, 0.5);
		padding-top: 4rem;
		padding-bottom: 4rem;
		color: white;
	}

	.footer-left {
		text-align: center;
		width: 50%;
	}

	.footer-right {
		text-align: center;
		width: 50%;
	}

	.footer-center {
		display: none;
	}

	.hero-flex {
		height: 100% !important;
	}

	.landing-location {
		width: 100%;
		text-align: center;
	}

	.insieme {
		max-width: 300px;
		bottom: -131px;
		right: -25px;
		position: absolute;
	}

	.page-title-container {
		top: 45%;
		display: block;
	}

	.page-title {
		width: 100%;
		top: 20%;
		text-align: left;
		left: 0 !important;
		margin-left: 0 !important;
	}

	.cappello-container {
		margin: 4rem auto !important;
		text-align: center !important;
	}

	.small-card {
		display: none;
	}

	.item-text {
		margin: -2rem 4rem 8rem 4rem;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		flex-basis: 100% !important; /* One item per row */
	}

	.hero-flexbox-item {
		flex-basis: 100%;
	}

	.hero-flexbox-item-2 {
		display: none;
	}

	.sub-cappello {
		text-align: center;
		padding-left: 0 !important;
		margin-top: 2rem;
		margin-bottom: 4rem;
	}

	.container-description {
		padding-left: 0;
		padding-right: 2rem;
	}

	.cappello {
		margin: 2rem;
		text-align: center;
	}

	.container-footer > img {
		max-width: 500px;
	}
}

@media (max-width: 600px) {
	.flex-container-cards > .flex-item-card {
		flex-basis: 55%;
	}

	.item-text {
		margin: -2rem 2rem 8rem 2rem;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	}

	.page-title {
		/*padding-left: 2rem;*/
	}

	.ctaButton {
		top: 8rem;
		left: 2rem;
	}

	.flex-container-list > .flex-item-card-1 {
		padding-left: 0rem;
	}

	.sub-cappello {
		padding-left: 2rem;
		font-size: 30px;
	}

	.corsi_list {
		padding-left: 2rem !important;
	}

	.corsi_list > li {
		padding-left: 0 !important;
	}

	.corsi_list > li > span {
		font-size: 24px;
	}

	.flex-item-description {
		flex-basis: 100%;
	}

	.small-card {
		display: none;
	}

	.rounded-image {
		display: none;
	}

	.sgl-logo {
		display: none;
	}

	.menu-items {
		display: none;
	}
}

@media (max-width: 400px) {
	.landing-location {
		margin-bottom: .5rem !important;
	}
	.container-description {
		margin: 2rem;
	}
}

/* example */
.card {
	background-color: rgb(221, 221, 221);
	border-radius: 20px;
	margin: 20px;
	padding: 20px;
	width: 280px;
	height: 450px;
	text-align: center;
	position: relative;
	display: inline-block;
	transition: transform 330ms ease-in-out;
}

.card:hover {
	transform: scale(1.1) rotate(1deg);
}


.image-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
	position: relative;
	overflow: hidden;
}

.image-container::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 14px;
	width: 90%;
	height: 100%;
	background: linear-gradient(to top, rgba(0, 255, 255, 0.3) 30%, transparent 0%);
	pointer-events: none;
	border-bottom-left-radius: 10%;
	border-bottom-right-radius: 10%;
}

.image-container_moto {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
	position: relative;
	overflow: hidden;
}

.image-container_moto::after {
	content: "";
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(to top, #e91e63a1 30%, transparent 0%);
	pointer-events: none;
	border-bottom-left-radius: 10%;
	border-bottom-right-radius: 10%;
}

.image-container_moto > .card > .text-container > .custom-button {
	background: red;
}

.card-title {
	color: white;
	font-size: 20px;
	font-weight: bold;
	position: absolute;
	top: 200px;
	z-index: 1;

}

.image {
	width: 90%;
	height: 200px;
	border-radius: 10%;
}

.image_moto {
	width: 100%;
	height: 252px;
	border-radius: 10%;
}


.text-container {
	margin-top: 20px;
	color: black !important;
}

.line {
	margin-bottom: 10px;
}

.line1 {
	font-size: 20px;
	font-weight: bold;
}

.line2 {
	font-size: 15px;
	font-family: 'rock salt';
	margin-bottom: 20px;
}

.line3 {
	font-size: 14px;
	margin-bottom: 30px;
}

.line4 {
	font-size: 12px;
	font-weight: bold;
}

/*Small Card*/
.small-card {
	position: absolute;
	margin-top: 15%;
	left: 75%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.small-card .bluetape {
	position: absolute;
	width: 70%;
	top: -70px;
	left: 35px;
}

.small-card .card {
	background-color: rgb(221, 221, 221);
	border-radius: 20px;
	margin: 20px;
	padding: 20px;
	width: 210px;
	height: 350px;
	text-align: center;
	position: relative;
}

.small-card .image-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
	position: relative;
	overflow: hidden;
}

.small-card .image-container::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;
	background: linear-gradient(to top, rgba(103, 235, 255, 0.5) 30%, transparent 0%);
	pointer-events: none;
	border-bottom-left-radius: 10%;
	border-bottom-right-radius: 10%;
}

.small-card .card-title {
	color: white;
	font-size: 15px;
	font-weight: bold;
	position: absolute;
	top: 119px;
	z-index: 1;

}

.small-card .image {
	width: 100%;
	height: 150px;
	border-radius: 10%;
}


.small-card .text-container {
	margin-top: 20px;
}

.small-card .line {
	margin-bottom: 10px;
}

.small-card .line1 {
	font-size: 15px;
	font-weight: bold;
}

.small-card .line2 {
	font-size: 9px;
	font-weight: bold;
	font-family: 'rock salt';
	margin-bottom: 20px;
}

.small-card .line3 {
	font-size: 11px;
	margin-bottom: 20px;
}

.small-card .line4 {
	font-size: 8px;
	font-weight: bold;
}

/* Card Tape CSS */
.tapeTLH {
	position: absolute;
	top: -50px;
	left: 15px;
	width: 100px;
	height: 100px;
	transform: rotate(90deg);
}

.tapeTLV {
	position: absolute;
	top: 10px;
	left: -50px;
	width: 100px;
	height: 100px;
	transform: rotate(0deg);
}

.tapeTRH {
	position: absolute;
	top: -50px;
	left: 200px;
	width: 100px;
	height: 100px;
	transform: rotate(90deg);
}

.tapeTRV {
	position: absolute;
	top: 10px;
	left: 267px;
	width: 100px;
	height: 100px;
	transform: rotate(0deg);
}

.tapeBLH {
	position: absolute;
	top: 435px;
	left: 20px;
	width: 100px;
	height: 100px;
	transform: rotate(90deg);
}

.tapeBLV {
	position: absolute;
	top: 377px;
	left: -50px;
	width: 100px;
	height: 100px;
	transform: rotate(0deg);
}

.tapeBRH {
	position: absolute;
	top: 437px;
	left: 183px;
	width: 100px;
	height: 100px;
	transform: rotate(90deg);
}

.tapeBRV {
	position: absolute;
	top: 388px;
	left: 268px;
	width: 100px;
	height: 100px;
	transform: rotate(0deg);
}
